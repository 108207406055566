<template>
  <div class="dashboard__container--body">
    <Loader v-if="!groupShifts || groupShifts.length == 0" />
    <div class="dashboard__container--body--col flex" v-for="(shift, index) in groupShifts" :key="shift.id">
      <ShiftCard :shift="shift"  />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import ShiftCard from '@/components/Shifts/ShiftCard.vue'

export default {
  name: 'groupShifts',
  computed: {
    ...mapState(['userProfile', 'currentUser', 'group', 'groupShifts']),
  },
  created () {
    this.$store.dispatch("getGroupShifts", this.$route.params.id)
  },
  components: {
    Loader,
    ShiftCard
  },
  beforeDestroy() {
    this.$store.dispatch("clearGroupState")
  }
}
</script>